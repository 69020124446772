<template>
	<div class="body">
		<div :key="index" v-for="(item,index) in list" class="item">
			<div :style="{'backgroundColor': item.state==1?'rgb(19,91,207)':'rgb(130,15,15)' }"
				style="height: 40px; line-height: 40px; box-sizing: border-box; padding-left: 10px; display: flex; flex-direction: row;">
				<div style="color: white;font-weight: bolder;flex-grow: 1; width: 0;">
					{{item.title}}
				</div>
				<div @click="edit(item)" style="margin-right: 10px; color: white; font-size: 14px; cursor: pointer;">
					编辑
				</div>
			</div>

			<div style="box-sizing: border-box; padding: 10px;">
				<div class="line">
					<div class="label">
						执行开关
					</div>
					<div style="flex-grow: 1;">
						{{item.state==1?"开":"关"}}
					</div>
				</div>
				<div class="line">
					<div class="label">
						执行周期：
					</div>
					<div style="flex-grow: 1;">
						{{item.timer}}秒/次
					</div>
				</div>
				<div class="line">
					<div class="label">
						执行次数：
					</div>
					<div style="flex-grow: 1;">
						{{item.count}}
					</div>
				</div>
				<div class="line">
					<div class="label">
						最新执行时间：
					</div>
					<div style="flex-grow: 1;">
						{{item.lastTime?item.lastTime:"未执行过"}}
					</div>
				</div>
				<div class="line">
					<div class="label">
						请求地址：
					</div>
					<div style="flex-grow: 1; width: 0;overflow:hidden;text-overflow:ellipsis;white-space:nowrap">
						<el-tooltip class="box-item" effect="dark" :content="item.url" placement="right-start">
							<div>{{item.url?item.url:"未配置"}}</div>
						</el-tooltip>
					</div>
				</div>
			</div>
		</div>


		<el-dialog title="编辑" v-model="dialogVisible" width="30%">
			<el-form>
				<el-form-item label="任务名称">
					<el-input v-model="editItem.title"></el-input>
				</el-form-item>
				<el-form-item label="执行周期">
					<el-input v-model="editItem.timer"></el-input>
				</el-form-item>
				<el-form-item label="请求地址">
					<el-input v-model="editItem.url"></el-input>
				</el-form-item>
				<el-form-item label="任务开关">
					<el-switch :active-value="1" :inactive-value="0" v-model="editItem.state" />
				</el-form-item>
			</el-form>
			<div style="text-align: center;">
				<el-button @click="postData" type="primary">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getTaskList,
		updateTask
	} from "../../api/task.js"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				list: [],
				dialogVisible: false,
				editItem: {}
			}
		},

		mounted() {
			this.getList()
		},

		methods: {
			getList() {
				let that = this
				getTaskList().then(res => {
					that.list = res.data.data
				})
			},

			edit(data) {
				this.dialogVisible = true
				this.editItem = data
			},

			postData() {
				let that = this
				updateTask(this.editItem).then(() => {
					ElMessage({
						message: '操作成功',
						type: 'success',
					})
					that.getList()
					that.dialogVisible = false
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.item {
		width: 400px;
		box-shadow: 0px 2px 6px rgba(0, 0, 0, .2);
		margin: 10px;
	}

	.line {
		display: flex;
		flex-direction: row;
		height: 30px;
		line-height: 30px;
		font-size: 14px;
	}

	.label {
		width: 120px;
	}
</style>
